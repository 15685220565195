import Swup from 'swup';
import SwupScriptsPlugin from '@swup/scripts-plugin';
import SwupScrollPlugin from '@swup/scroll-plugin';
import SwupGaPlugin from '@swup/ga-plugin';

(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);

  const navigation = document.querySelector('#main-navigation');
  const navigationMask = document.querySelector('#main-navigation-mask');
  const menuTrigger = document.querySelector('#menu-trigger');

  function handleDOMContentLoaded() {
    menuTrigger.addEventListener('click', openCloseMenu);

    const swup = new Swup({
      containers: [
        '.main-navigation__content',
        '.main-content',
        '.js-ajax'
      ],
      plugins: [
        new SwupScriptsPlugin(),
        new SwupScrollPlugin(),
        new SwupGaPlugin()
      ]
    });

    swup.on('clickLink', closeMenu);
  }

  function closeMenu() {
    menuTrigger.classList.remove('menu-trigger--open');
    navigation.classList.remove('main-navigation--open');
    navigationMask.classList.remove('main-navigation-mask--open');
  }

  function openCloseMenu(e) {
    menuTrigger.classList.toggle('menu-trigger--open');
    navigation.classList.toggle('main-navigation--open');
    navigationMask.classList.toggle('main-navigation-mask--open');
  }
})();
